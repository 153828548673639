import React from "react"
import PageHeader from "../components/Header"
// import UserDetail from "../components/UserDetail"
// import PageSlider from "../components/Slider"
// import WelcomePage from "../components/WelcomePage"
// import Selector from "../components/Selector"
// import FreeTrial from "../components/FreeTrial"
import PageFooter from "../components/Footer"
// import { useTranslation } from 'react-i18next'

import "../PravidlaPovinnosti.css"

const LetnyTaborPravidla = () => {
    // const {t} = useTranslation('translation')

    // const dispatch = useDispatch()
    // const { userProfile } = useSelector((state) => state)
    // const debug = JSON.stringify(userProfile, null, 2)

    // const [hasRoles, setHasRoles] = useState(false)
    // const [hasAwards, setHasAwards] = useState(false)
    // const [hasAdultRanks, setHasAdultRanks] = useState(false)
    
    // useEffect(() => {
    //     if (userProfile.roles) { setHasRoles(true) }
    //     if (userProfile.awards) { setHasAwards(true) }
    //     if (userProfile.adultRanks) { setHasAdultRanks(true) }
    // }, [userProfile])
    

    return (
        <div>
            <PageHeader />


            <div id="page_header" className="page-subheader site-subheader-cst">
                <div className="bgback" />
                <div className="th-sparkles" />
                <div className="kl-bg-source">
                    <div className="kl-bg-source__bgimage" style={{backgroundImage: 'url(images/general-bg-pc.jpg)', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundPositionX: 'center', backgroundPositionY: 'center', backgroundSize: 'cover'}} />
                    <div className="kl-bg-source__overlay" style={{backgroundColor: 'rgba(53,53,53,0.1)'}} />
                </div>


                <div className="ph-content-wrap d-flex">
                <div className="container align-self-center">
                    <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="clearfix" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6" style={{backgroundColor: 'rgba(0,0,0,0.5)', padding: 15,}}>
                        <div className="subheader-titles">
                        <h2 className="subheader-maintitle">PRAVIDLÁ A PODMIENKY ÚČASTI</h2>
                        <h4 className="subheader-subtitle">Denný letný tabor</h4>
                        </div>
                    </div>
                    </div>
                </div>
                </div>


                {/* <div className="kl-bottommask kl-bottommask--mask3">
                
                <svg width="2700px" height="57px" className="svgmask" viewBox="0 0 2700 57" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                    <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="filter-mask3">
                    <feOffset dx={0} dy={3} in="SourceAlpha" result="shadowOffsetInner1" />
                    <feGaussianBlur stdDeviation={2} in="shadowOffsetInner1" result="shadowBlurInner1" />
                    <feComposite in="shadowBlurInner1" in2="SourceAlpha" operator="arithmetic" k2={-1} k3={1} result="shadowInnerInner1" />
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.4 0" in="shadowInnerInner1" type="matrix" result="shadowMatrixInner1" />
                    <feMerge>
                        <feMergeNode in="SourceGraphic" />
                        <feMergeNode in="shadowMatrixInner1" />
                    </feMerge>
                    </filter>
                </defs>
                <path d="M-2,57 L-2,34.007 L1268,34.007 L1284,34.007 C1284,34.007 1291.89,33.258 1298,31.024 C1304.11,28.79 1329,11 1329,11 L1342,2 C1342,2 1345.121,-0.038 1350,-1.64313008e-14 C1355.267,-0.03 1358,2 1358,2 L1371,11 C1371,11 1395.89,28.79 1402,31.024 C1408.11,33.258 1416,34.007 1416,34.007 L1432,34.007 L2702,34.007 L2702,57 L1350,57 L-2,57 Z" className="bmask-bgfill" filter="url(#filter-mask3)" fill="#fbfbfb" />
                </svg>

              <i className="fas fa-angle-down" />
            </div> */}
            
            </div>




            <section id="services" className="hg_section pt-50 pb-100">
            <div className="container">

                <div className="row mb-30">
                <div className="col-sm-12 col-md-12">
                    <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                    <h3 className="tbk__title kl-font-alt fs-xxl tcolor fw-bold">
                    PRAVIDLÁ A PODMIENKY ÚČASTI V DENNOM LETNOM TÁBORE
                    </h3>

                    

                    <h4 className="tbk__icon fs-l tcolor">ZÁKLADNÉ PRAVIDLÁ ÚČASTI V DENNOM LETNOM TÁBORE</h4>

                    <ol className="pravidla">
                        <li>Nástup do denného letneho táboru je denne medzi 7:30 až 8:30. Denne prebehnú 2-3 BJJ tréningy. </li>
                        <li>Účastník je oblečený v GI (v kimone) alebo športovo a so zreteľom na skutočnosť, že sa bude venovať športovým tréningom a zábave. Je vhodné nosiť si prezlečenie - rezervné tričko a tenisky na trénovanie. </li>
                        <li>Pre deti - je zabezpečený pitný režim, malé občerstvenie (ovocné desiaty a olovranty), varené teplé obedy.</li>
                        <li>Účastník bez vedomia vedúceho neopustí „svoj“ tréningový priestor. Všetky programy sú maximálne dobrovoľné, ak chce účastník zmeniť „svoj“ tréningový program, je povinný dohodnúť sa o tejto zmene s trénerom. </li>
                        <li>Každý účastník chráni svoje zdravie a zdravie ostatných účastníkov, rešpektuje osobnosť a potreby ostatných účastníkov, chráni svoj i zverený majetok, rešpektuje a riadi sa pokynmi trénera.</li>
                    </ol>
                    


                    <h4 className="tbk__icon fs-l tcolor">ZÁVÄZNÉ PODMIENKY ÚČASTI V DENNOM LETNOM TÁBORE - DENNÝ LETNÝ TÁBOR</h4>
                    <ol className="pravidla">
                        <li>Usporiadateľ tábora OZ BJJ Levice pripravia pre účastníkov tábora program zameraný na pohybovú prípravu, rozvoj schopností, kreativitu. Pre deti bude vytvorená atmosféra dobrovoľnosti.</li>
                        <li>Základné informácie o DENNOM LETNOM TÁBORE sú zverejnené aj na www.bjjlevice.sk/letny-tabor. V prípade ďalších otázok alebo nejasností volajte: 0918 107 782 alebo mailom na info@bjjlevice.sk</li>
                        <li>Stravovanie: Účastníci tábora majú zabezpečený teplý obed, ovocné občerstvenie a pitný režim.</li>
                        <li>V dennom letnom tábore platia základné pravidlá účasti v tábore, ktorými sa účastníci musia riadiť. Dodržiavanie týchto pravidiel je nevyhnutné v záujme ochrany zdravia účastníkov, ako aj pre hladký priebeh programu tábora. Prípadné opakované, či hrubé porušenie pravidiel môže viesť k vylúčeniu dieťaťa z tábora. V takomto prípade zaniká rodičovi nárok na vrátenie celej sumy, alebo jej časti.</li>
                        <li>Rodič (zákonný zástupca) účastníka tábora – alebo účastník samotný, ak je starší ako 18 rokov, je povinný:</li>
                        <ul className="pravidlaUl">
                            <li>Prihlásiť seba, alebo dieťa a zaplatiť účastnícky poplatok špecifikovaný pred odoslaním prihlášky. Poplatok sa uhrádza v hotovosti, v prvý deň tábora.</li>
                            <li>Pri nástupe dieťaťa do tábora odovzdať fotokópiu Preukazu poistenca a „Prehlásenie o bezinfekčnosti účastníka“.</li>
                            {/* <li>Pri nástupe dieťaťa do tábora odovzdať fotokópiu Preukazu poistenca.</li> */}
                            <li>Počas tábora denne priviezť/odovzdať dieťa OSOBNE lektorom do denného letného tábora alebo priestoru, kde denný tábor prebieha, RÁNO MEDZI 7:30 – 8:30, a vyzdvihnúť medzi 15:00 – 16:30. Ak deti môžu chodiť domov samé, prinesú písomný súhlas zákonného zástupcu, že môžu ísť domov samé.</li>
                            <li>uhradiť materiálne škody, ktoré jeho dieťa spôsobí nerešpektovaním Základných pravidiel účasti v tábore.</li>
                        </ul>

                        <li>V prípade zrušenia tábora oznámi jeho organizátor túto skutočnosť zákonnému zástupcovi účastníka najneskôr 5 dní pred jeho začatím (rozhodujúci je dátum odoslania).</li>
                        <li>OZ BJJ Levice môže zrušiť tábor v nasledujúcich prípadoch:
                            <ul className="pravidlaUl">
                                <li>z dôvodu prekážok brániacim poskytnúť dohodnuté služby alebo zabezpečiť adekvátnu náhradu,</li>
                                <li>z dôvodu neobsadenia tábora dostatočným počtom účastníkov (minimálny počet účastníkov na jeden turnus je 10 osôb),</li>
                                <li>z dôvodu vyššej moci (t.j. politické udalosti, extrémne prírodné javy, karanténa a pod.).</li>
                            </ul>

                            <p>V prípade zrušenia tábora zo spomínaných dôvodov bude rodičovi vrátená plná zaplatená cena účastníckeho poplatku.</p>
                        </li>


                        <li>
                            Ak sa rodič rozhodne zrušiť účasť svojho dieťaťa v tábore, musí zaplatiť nasledovné storno pokuty:
                            <ul className="pravidlaUl">
                                <li>15 –  2 dni pred nástupom –   60 % z výšky účastníckeho poplatku</li>
                                <li>24 hodín pred nástupom    –  100 % z výšky účastníckeho poplatku</li>
                            </ul>
                            <p>Nástupom účastníka do tábora sa stráca nárok na vrátenie účastníckeho poplatku.</p>
                            <p>Ak zákonný zástupca pri nástupe zamlčí nejaký fakt týkajúci sa zdravotného stavu účastníka, na základe ktorého sa u účastníka vyskytne zdravotný problém, môže byť účastník z tábora vylúčený. V tomto prípade zaniká zákonnému zástupcovi nárok na vrátenie zvyšnej sumy z účastníckeho poplatku.</p>
                        </li>

                    </ol>







                    {/* 
                    <div className="tbk__symbol">
                        <span className="tbk__icon fs-xxl tcolor fas fa-ellipsis-h" />
                    </div> 
                    */}
                    {/* 
                    <h4 className="tbk__subtitle fs-m tcolor">
                        WE'RE GOOD AT SOME STUFF AND VERY GOOD AT OTHER STUFF
                    </h4> 
                    */}

                    </div>
                </div>
                </div>

                {/*
                <div className="row mb-30">
                <div className="col-sm-12 col-md-12">
                    <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                    <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">
                    {t('program.pc6')}
                    </h3>
                    <p>{t('program.pc7')}</p>
                    </div>
                </div>
                </div>

                <div className="row mb-30">
                <div className="col-sm-12 col-md-12">
                    <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                    <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">
                    {t('program.pc8')}
                    </h3>
                    <p>{t('program.pc9')}</p>
                    
                    <p>{t('program.pc10')}</p>
                    </div>
                </div>
                </div>
                 */}

            </div>
            </section>





            
            <PageFooter />
        </div>
    )
}

export default LetnyTaborPravidla

