import axios from 'axios'
import {NotificationManager} from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import UserService from './UserService'

const HttpMethods = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
}

const _axios = axios.create({
    baseURL: `https://api.bjjlevice.sk`,
})


const configure = () => {
    _axios.interceptors.request.use((config) => {
      if (UserService.isLoggedIn()) {
        const cb = () => {
          config.headers.Authorization = `Bearer ${UserService.getToken()}`
          return Promise.resolve(config)
        }
        return UserService.updateToken(cb)
      }
    })
  }

const responseErrorHandler = error => {
    //if (error.response.status === 401) {
    //     console.log("redirect to login page")
    //}

    if (error.response.status === 400) {
        console.log(JSON.stringify(error.response, null, 2))
        NotificationManager.error('400 :( Error returned from backend.', 'Error', 3000)
    }
    else if (error.response.status === 500) {
        // console.log(JSON.stringify(error.response, null, 2))
        NotificationManager.error('500 :( Error returned from backend.', 'Error', 3000)
    }
    else if (error.response.status === 404) {
        NotificationManager.error('404, Page not found.', 'Error', 3000)
    }
    else {
        NotificationManager.error('Hmm :( Error returned from backend.', 'Error', 3000)
    }

    
}


// Add a response interceptor
_axios.interceptors.response.use(function (response) {
    // console.log("Do something with response data")
    return response
}, function (error) {
    // console.log("Do something with response error")
    responseErrorHandler(error)
    return Promise.reject(error)
})


const getAxiosClient = () => _axios

const HttpService = {
    HttpMethods,
    configure,
    getAxiosClient,
}

export default HttpService
