// import { applyMiddleware, compose, createStore } from "redux";
// import axiosMiddleware from "redux-axios-middleware";
// import logger from "redux-logger";
// import thunk from "redux-thunk";
// import rootReducer from "../modules";
// import HttpService from "./HttpService";

// const setup = () => {
//     const enhancers = [];
//     const middleware = [
//         thunk,
//         axiosMiddleware(HttpService.getAxiosClient())
//     ];

//     if (process.env.NODE_ENV === 'development') {
//         enhancers.push(applyMiddleware(logger));
//     }

//     const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

//     return createStore(rootReducer, composedEnhancers);
// };

// const StoreService = {
//     setup,
// };

// export default StoreService;

import { applyMiddleware, compose, createStore } from "redux";
import axiosMiddleware from "redux-axios-middleware";
import logger from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "../modules/reducers";
import HttpService from "./HttpService";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../modules/sagas";

const sagaMiddleware = createSagaMiddleware();

const setup = () => {
    const enhancers = [];
    const middleware = [
        thunk,
        sagaMiddleware,
        axiosMiddleware(HttpService.getAxiosClient())
    ];

    if (process.env.NODE_ENV === 'development') {
        enhancers.push(applyMiddleware(logger));
    }

    // const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
    const composedEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store =  createStore(
        rootReducer, 
        composedEnhancers(
            // applyMiddleware(...middleware)
            applyMiddleware(...middleware), ...enhancers
    ));
    sagaMiddleware.run(rootSaga);
    return store;
  
        
};

const StoreService = {
    setup,
};

export default StoreService;

