import { SUCCESS_SUFFIX, FAIL_SUFFIX } from "redux-axios-middleware"

import {
  GET_ESHOP_PRODUCTS,  
} from "./actionType";

const INIT_STATE = {
  eshopProducts: [],
  error: {},
  // isCategorySuccess: false
}

const EshopProductsReducer = (state = INIT_STATE, action) => {  

  switch (action.type) {
    case GET_ESHOP_PRODUCTS + SUCCESS_SUFFIX:
      return {
        ...state,
        // isCategorySuccess: true,
        eshopProducts: action.payload.data,
      }

    case GET_ESHOP_PRODUCTS + FAIL_SUFFIX:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state;
  }

}
export default EshopProductsReducer
