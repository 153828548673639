import {
  LANG,  
} from "./actionType";

const LanguageReducer = (state = 'sk', action) => {
  switch (action.type) {
    case LANG:
      // return (action.payload) ? action.payload.data : []
      return action.payload.lang
    default:
      return state
  }
}

export default LanguageReducer
