import React from "react"
import PhotoAlbum from "react-photo-album"

// import { useTranslation } from 'react-i18next'


const PhotoAlbumComponent = () => {
  // const {t} = useTranslation('translation')

  const photos = [
    { src: "/images/bjjdeti/IMG_3819.jpg", width: 1500, height: 1000 },
    { src: "/images/bjjdeti/IMG_9482.jpg", width: 1500, height: 1000 },
    { src: "/images/bjjdeti/IMG_9493.jpg", width: 1500, height: 1000 },
    { src: "/images/bjjdeti/IMG_9499.jpg", width: 1500, height: 1000 },
    { src: "/images/bjjdeti/IMG_9509.jpg", width: 1500, height: 1000 },
    { src: "/images/bjjdeti/IMG_9522.jpg", width: 1500, height: 1000 },
    { src: "/images/bjjdeti/IMG_9545.jpg", width: 1500, height: 1000 },
    { src: "/images/bjjdeti/IMG_9551.jpg", width: 1500, height: 1000 },
    { src: "/images/bjjdeti/IMG_9552.jpg", width: 1500, height: 1000 },
    { src: "/images/bjjdeti/IMG_9570.jpg", width: 1500, height: 1000 },
    { src: "/images/bjjdeti/IMG_9576.jpg", width: 1500, height: 1000 },
    { src: "/images/bjjdeti/IMG_9602.jpg", width: 1500, height: 1000 },
    { src: "/images/bjjdeti/IMG_9639.jpg", width: 1500, height: 1000 },
    { src: "/images/bjjdeti/IMG_9645.jpg", width: 1500, height: 1000 },
    { src: "/images/bjjdeti/IMG_9647.jpg", width: 1500, height: 1000 },
    { src: "/images/bjjdeti/IMG_9664.jpg", width: 1500, height: 1000 }
  ];



  return (
    <section className="hg_section">
      <div className="container">
        <h3 className="fw-semibold fs-xxxl fs-xs-xl tcolor tbk__title fs-xxl fw-thin">Fotografie</h3>
        
        <PhotoAlbum layout="rows" photos={photos} />
      </div>
    </section>
  )
}

export default PhotoAlbumComponent
