// import {Link} from "react-router-dom";


function KidsComponent(props) {

    return (
        // <section className="content">
        //     <div className="error-page">
        //         <h2 className="headline text-warning"> 404</h2>
        //         <div className="error-content">
        //             <h3><i className="fas fa-exclamation-triangle text-warning" /> Oops! Page not found. </h3>
        //             <p>
        //                 We could not find the page you were looking for.
        //                 Meanwhile, you may <Link to={`/`}>Dashboard</Link>.
        //             </p>
        //         </div>
        //     </div>
        // </section>

        <div>





            <section className="hg_section">
                
                
                <div className="container">
                    
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-6">
                                    {/* <div className="col-sm-12 col-md-12 col-lg-12"> */}
                                        <div className="left-side">
                                            <h3 className="fw-semibold fs-xxxl fs-xs-xl tcolor tbk__title fs-xxl fw-thin">Brazílske jiu-jitsu pre deti</h3>
                                            {/* <img src="images/gracie-bullyproof-trans.png" alt="The Gracie Combatives® Program" /><br /><br /><br /> */}
                                            <p>V programe BJJ pre deti je naším cieľom vzbudiť vo Vašich deťoch neotrasiteľnú dôveru v seba samého, naučiť ich zvládať a riešiť konfliktné situácie, viesť ich k vzájomnej spolupráci, disciplíne, odvahe i ovládaniu emócii. Hry a modelové situácie sú plné zábavy, kde ľahko deti nadväzujú nové priateľstvá. Vďaka nenásilnej povahe techník vyučovaných v programe BJJ pre deti sa tento program ukázal ako jeden z mála účinných protiopatrení proti šikanovaniu pre deti vo veku od 5 do 12 rokov. Naučíme Vaše deti používať verbálnu obranu a niekoľko nenásilných techník sebaobrany. Môžeme vás ubezpečiť, že program BJJ pre deti si Vaše deti absolútne zamilujú!</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-6">
                                        <div className="fluid-width-video-wrapper" style={{ paddingTop: '75%' }}>
                                            <iframe title="bp1" src="https://www.youtube.com/embed/ybQ__WdAqvE?rel=0&controls=0&showinfo=0" allowFullScreen />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            {/* <div className="col-sm-12 col-md-12">
                                <p>Naučíme vaše dieťa používať verbálnu asertivitu na odstrašenie násilníkov a niekoľko nenásilných techník sebaobrany, aby zostalo v bezpečí v prípade fyzického napadnutia. Dôležité je, že neučíme, ako udierať alebo kopať, pretože to často spôsobí viac škody ako úžitku. Namiesto toho používame kontrolu založenú na páke na neutralizáciu hrozieb bez násilia. Mnohé školy prijali obranný charakter techník BJJ pre deti ako rozumnú, nenásilnú reakciu na šikanovanie a niektoré dokonca organizujú kurzy BJJ pre deti na akademickej pôde! Pointa je: pripravíme vaše dieťa na to, aby sa ubránilo násilníkom bez toho, aby sa stal jedným z nich.</p><p>Ak máte záujem vštepiť svojmu dieťaťu neotrasiteľnú sebadôveru a zároveň posilniť pozitívne hodnoty a dobrý charakter, program BJJ pre deti je pre vás! Aj keď vaše dieťa v minulosti vyskúšalo brazílske jiu-jitsu (BJJ) alebo iné bojové športy a malo negatívnu skúsenosť, môžeme vás ubezpečiť, že program BJJ pre deti si absolútne zamiluje!</p>
                            </div> */}
                        </div>
                    </div>

                    <div className="row mb-30 pt-30">
                        <div className="col-sm-12 col-md-12">
                            <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                                <h3 className=" fw-semibold fs-xxxl fs-xs-xl tcolor tbk__title fs-xxl fw-thin">Čo je to VERBÁLNY tréning Jiu-Jitsu?</h3>
                                <p>So slovným obťažovaním sa dnes deti stretávajú v školách, v čase po vyučovaní, pri hrách s rovesníkmi, v rôznych prostrediach. V programe BJJ pre deti robíme tréning verbálnej asertivity, alebo ako to radi nazývame „verbálne Jiu-Jitsu“. Vyvinuli sme sériu cvičení na hranie rolí, aby sme deti naučili, ako identifikovať a zvládať bežné hrozby. Deti sa učia ako asertívne hovoriť, keď je to potrebné, ale tiež sa učia používať priamy očný kontakt a sebavedomé držanie tela, aby odradili kohokoľvek, kto by mal tendenciu slovne obťažovať.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-30">
                        <div className="col-sm-12 col-md-12">
                            <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                                <h3 className="fw-semibold fs-xxxl fs-xs-xl tcolor tbk__title fs-xxl fw-thin">Perfektné programy pre každý vek</h3>
                                <p>Naše BJJ programy pre deti sú ,,ušité na mieru“ pre každého. Program pre Vaše dieťa vyberieme na základe veku a úrovne schopností. Každé dieťa dostane na svojich hodinách dokonalú rovnováhu medzi zábavou a výzvou. Všetky deti začínajú v super-hravých programoch vstupnej úrovne pre svoju vekovú skupinu a akonáhle dieťa dosiahne určitú úroveň zrelosti, postúpi do ďalšieho tréningového programu.</p>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-3">
                            <div className="services_box services_box--boxed sb--hasicon">
                                <div className="services_box__inner clearfix">
                                    <div className="services_box__content">
                                        <h4 className="services_box__title">Jiu jitsu plyšáci<br />(3-5 rokov)</h4>
                                        <div className="services_box__desc">
                                            <p>Jiu jitsu plyšáci je skvelým štartom pre našich najmenších. Táto nová 30-minútová hodina je plná zábavných hier a jednoduchých techník s cieľom vybudovať sebavedomie Vášho dieťaťa a zároveň ho nadchnúť a pripraviť na prechod do skupiny Malých šampiónov. (5-8rokov). V programe Jiu jitsu plyšákov musí každé dieťa sprevádzať rodič, pretože deti budú techniky/hry robiť iba s dospelými. Aj Vy, ako rodič, sa naučíte spolu so svojim dieťaťom množstvo skvelých hier/techník, ktoré Vás budú zaručene baviť. Rodič nemusí platiť za triedu ani sa zapísať do samostatného členstva, ale musí mať vlastnú uniformu/gi.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-3">
                            <div className="services_box services_box--boxed sb--hasicon">
                                <div className="services_box__inner clearfix">
                                    <div className="services_box__content">
                                        <h4 className="services_box__title">Malí šampióni<br />(5-8 rokov)</h4>
                                        <div className="services_box__desc">
                                            <p>V programe Malých šampiónov  učíme pomocou našich rokmi overených hier základné techniky sebaobrany a zároveň našim mladým šampiónom vštepujeme základné princípy brazílskeho jiu jitsu. Tajomstvo úspechu tohto programu tkvie v tom, že naše hodiny sú tak zábavné, že deti nechcú ísť domov! Keď dieťa zvládne všetkých desať hier, postúpi do programu Mladí zápasníci.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className="services_box services_box--boxed sb--hasicon">
                                <div className="services_box__inner clearfix">
                                    <div className="services_box__content">
                                        <h4 className="services_box__title">Mladí zápasníci<br />(8-12 rokov)</h4>
                                        <div className="services_box__desc">
                                            <p>V programe Mladí zápasníci sa zameriavame na 33 nenásilných techník sebaobrany, ktoré učia deti „neutralizovať a vyjednávať“ s protivníkom. Stratégie verbálnej asertivity sú hlavnou časťou tohto programu. Dieťa na začiatok nepotrebuje absolútne žiadne skúsenosti a garantujeme citeľné zvýšenie sebavedomia Vášho dieťaťa v priebehu niekoľkých týždňov!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className="services_box services_box--boxed sb--hasicon">
                                <div className="services_box__inner clearfix">
                                    <div className="services_box__content">
                                        <h4 className="services_box__title">Black Belt Club<br />(Iba na pozvánku)</h4>
                                        <div className="services_box__desc">
                                            <p>Pozývame deti, ktoré vynikajú v programe Mladí zápasníci, aby sa pripojili k Black Belt Clubu. Toto je náš najpokročilejší mládežnícky program Jiu-Jitsu a vstup do Black Belt Club je najvyššou poctou pre každého kto trénuje Brazílske Jiu-Jitsu. Deti v tomto programe majú všetky vlastnosti potrebné pre úspech na ceste k čiernemu pásu: disciplínu, rešpekt, odvahu, vodcovstvo, lojalitu a súcit.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    

<section className="hg_section pt-80 pb-80">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 col-md-12">
        <div className="kl-title-block">
          <h3 className="tbk__title fs-xxl fw-thin">
            <span className="fw-semibold fs-xxxl fs-xs-xl tcolor">Ceny</span>
          </h3>
        </div>
      </div>
      <div className="col-sm-12 col-md-12">
        <div className="pricing-table-element" data-columns={4}>
          <div className="plan-column">
            <ul>
              <li className="plan-title">
                <div className="inner-cell">
                &nbsp;
                </div>
              </li>
              <li>
                <div className="inner-cell text-left pl-30 black">
                  náborový mesiac: (september)<br />
                  {/* <small>v cene je nove GI (kimono)</small> */}

                </div>
              </li>
              <li>
                <div className="inner-cell text-left pl-30 black">
                  1. trimester: (október - december)
                </div>
              </li>
              <li>
                <div className="inner-cell text-left pl-30 black">
                  2. trimester: (január - marec)
                </div>
              </li>
              <li>
                <div className="inner-cell text-left pl-30 black">
                  3. trimester: (apríl - jún)
                </div>
              </li>
              <li>
                <div className="inner-cell text-left pl-30 pr-30 black">
                  V prípade ročnej platby dopredu je 10% zľava
                </div>
              </li>
            </ul>
          </div>

          <div className="plan-column ">
            <ul>
              <li className="plan-title">
                <div className="inner-cell">
                Jiu jitsu plyšáci <br />(3-5 rokov)
                </div>
              </li>
              {/* <li className="subscription-price">
                <div className="inner-cell">
                  <span className="currency">$</span>
                  <span className="price">9.99</span>
                  per month
                </div>
              </li> */}
              <li>
                <div className="inner-cell">
                30 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                90 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                90 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                90 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                270 € / rok
                </div>
              </li>
            </ul>
          </div>

          <div className="plan-column ">
            <ul>
              <li className="plan-title">
                <div className="inner-cell">
                Malí šampióni <br />(5-8 rokov)
                </div>
              </li>
              {/* <li className="subscription-price">
                <div className="inner-cell">
                  <span className="currency">$</span>
                  <span className="price">9.99</span>
                  per month
                </div>
              </li> */}
              <li>
                <div className="inner-cell">
                45 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                135 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                135 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                135 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                405 € / rok
                </div>
              </li>
            </ul>
          </div>

          <div className="plan-column ">
            <ul>
              <li className="plan-title">
                <div className="inner-cell">
                Mladí zápasníci <br />(8-12 rokov)
                </div>
              </li>
              {/* <li className="subscription-price">
                <div className="inner-cell">
                  <span className="currency">$</span>
                  <span className="price">9.99</span>
                  per month
                </div>
              </li> */}
              <li>
                <div className="inner-cell">
                45 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                135 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                135 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                135 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                405 € / rok
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-12">
        <small><strong>Súrodenecká zľava (resp. pre ďalšieho člena rodiny) je 20%.</strong></small>
      </div>
    </div>
  </div>
</section>




                    
                    {/* <div className="row"><div className="col-sm-12 col-md-12"><div className="screenshot-box"><div className="row"><div className="col-sm-12 col-md-12 col-lg-6"><div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}><iframe title="bp2" src="https://www.youtube.com/embed/xWd1Zo4p4h0?rel=0&controls=0&showinfo=0" allowFullScreen /></div></div><div className="col-sm-12 col-md-12 col-lg-6"><div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}><iframe title="bp3" src="https://www.youtube.com/embed/UerCXwUxndg?rel=0&controls=0&showinfo=0" allowFullScreen /></div></div></div><div className="row"><div className="col-sm-12 col-md-12 col-lg-6"><div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}><iframe title="bp4" src="https://www.youtube.com/embed/y8isR_Y-kfk?rel=0&controls=0&showinfo=0" allowFullScreen /></div></div><div className="col-sm-12 col-md-12 col-lg-6"><div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}><iframe title="bp5" src="https://www.youtube.com/embed/XUw7tF0xiSY?rel=0&controls=0&showinfo=0" allowFullScreen /></div></div></div></div></div></div> */}
                    
                </div>
            </section>

        </div>
    )
}
export default KidsComponent