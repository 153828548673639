import React from 'react'
import { createRoot } from 'react-dom/client';
import App from './App'
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import StoreService from "./services/StoreService"
import * as serviceWorker from './services/ServiceWorker'
import "./i18next"

// import HttpService from "./services/HttpService"
// import UserService from "./services/UserService"

const store = StoreService.setup()


const root = createRoot(document.getElementById("page_wrapper"));
root.render(
    <Provider store={store} >
    <BrowserRouter>
        <App />
    </BrowserRouter>
    </Provider>,
)


// const container = document.getElementById('page_wrapper')
// const root = createRoot(container)
// const renderApp = () => root.render(
//     // root.render(
//     <Provider store={store} >
//       <React.Fragment>
//         <BrowserRouter basename={process.env.PUBLIC_URL}>
//           <App />
//         </BrowserRouter>
//       </React.Fragment>
//     </Provider>    
// )

// UserService.initKeycloak(renderApp);
// HttpService.configure();


serviceWorker.unregister()