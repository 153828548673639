import React from "react"
import PageHeader from "../components/Header"
// import UserDetail from "../components/UserDetail"
// import PageSlider from "../components/Slider"
// import WelcomePage from "../components/WelcomePage"
// import Selector from "../components/Selector"
// import FreeTrial from "../components/FreeTrial"
import PageFooter from "../components/Footer"
// import SummerCampComponent from "../components/SummerCampComponent"
// import NaborFormular from "../components/NaborFormular"
// import KidsComponent from "../components/KidsComponent"
// import PhotoAlbumComponent from "../components/PhotoAlbumComponent"
import HeaderImage from "../components/HeaderImage"
import EshopComponent from "../components/eshop/EshopComponent"



const Eshop = () => {
    return (
        <div>
            <PageHeader />
            <HeaderImage maintitle="E-shop" subtitle="do 12 rokov" img="images/general-bg-kids.jpg" />
            <EshopComponent />
            <PageFooter />
        </div>
    )
}

export default Eshop

