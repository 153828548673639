import {
  GET_ESHOP_CATEGORIES,

  } from "./actionType";
  import HttpService from "../../services/HttpService"


  export const getEshopCategories = (filterValues) => ({
    type: GET_ESHOP_CATEGORIES,
    payload: {
      request: {
        method: HttpService.HttpMethods.POST,
        url: '/eshop/categories/list',
        data: filterValues
      },
    }
  })
