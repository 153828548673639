import React from 'react'
import ModalProductDetail from './ModalProductDetail';

export default function EshopProducts({products}) {

    const [modal, setModal] = React.useState(false);
    const [selectedProduct, setSelectedProduct] = React.useState(null);

    const toggle = React.useCallback(() => {
      if (modal) {
        setModal(false);
        setSelectedProduct(null)
  
        // setSelectedData({});      
        // setIsEdit(false)
  
      } else {
        setModal(true);        
      }
    }, [modal])
  

    const handleProductDetail = (product) => {
        console.log(product)
        setModal(true)
        setSelectedProduct(product)
    }

    return (
        <div>
            {/* <h1 className="page-title fw-bold">
                FURS
            </h1> */}
            <p className="kl-store-result-count">
                Showing {products.length} results
            </p>
            <form className="kl-store-ordering" method="get">
                {/* <select name="orderby" className="orderby">
                    <option value="menu_order" selected="selected">Default sorting</option>
                    <option value="popularity">Sort by popularity</option>
                    <option value="rating">Sort by average rating</option>
                    <option value="date">Sort by newness</option>
                    <option value="price">Sort by price: low to high</option>
                    <option value="price-desc">Sort by price: high to low</option>
                </select> */}
            </form>

            <ul className="products clearfix">
                { products.map( (product, index) => 
                <li className="product" key={index} 
                    onClick={() => { const data = product; handleProductDetail(data); }}
                >
                    <div className="product-list-item prod-layout-classic">
                        <div className="hg_badge_container">
                        { product.tag_sale !== null &&
                            <span className="hg_badge_sale">
                                {product.tag_sale}
                            </span>
                        }
                        { product.tag_new !== null &&
                            <span className="hg_badge_new">
                                {product.tag_new}
                            </span>
                        }
                        </div>
                        <a>
                            <span className="image kw-prodimage">
                                {/* <img src="/images/_shop/noimage.png" className="kw-prodimage-img" alt="Noimage" title="NoImage" /> */}
                                <img src="/images/_shop/rg-bjjlevice1.png" className="kw-prodimage-img" alt="Kallyas Product" title="Kallyas Product" />
                                <img src="/images/_shop/rg-bjjlevice2.png" className="kw-prodimage-img-secondary" alt="Kallyas Product" title="Kallyas Product" />
                            </span>
                            <div className="details kw-details fixclear">
                                <h3 className="kw-details-title">
                                    {product.title}
                                </h3>
                                <span className="price">
                                    <del data-was="WAS">
                                        <span className="amount">{product.regular_price} &euro;</span>
                                    </del>
                                    <ins data-now="NOW">
                                        <span className="amount">{product.sale_price} &euro;</span>
                                    </ins>
                                </span>
                                {/* <div className="star-rating" title="Rated 0 out of 5">
                                    <span style={{ width: '0%' }}>
                                        <strong className="rating">0</strong> out of 5
                                    </span>
                                </div> */}
                            </div>
                        </a>
                        <div className="actions kw-actions">
                            <a href="#" rel="nofollow" data-product_id data-product_sku data-quantity={1}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={28} height={32} viewBox="0 0 28 32">
                                    <path className="svg-cart-icon" d="M26,8.91A1,1,0,0,0,25,8H20V6A6,6,0,1,0,8,6V8H3A1,1,0,0,0,2,8.91l-2,22A1,1,0,0,0,1,32H27a1,1,0,0,0,1-1.089ZM10,6a4,4,0,0,1,8,0V8H10V6ZM2.1,30L3.913,10H8v2.277a2,2,0,1,0,2,0V10h8v2.277a2,2,0,1,0,2,0V10h4.087L25.9,30H2.1Z" />
                                </svg>
                            </a>
                            {/* <a href="product-page.html">
                                <span className="more-icon fas fa-compress" />
                            </a> */}
                        </div>
                    </div>
                </li>
                )}

            </ul>
            {
                selectedProduct &&
                <ModalProductDetail modal={modal} setModal={setModal} toggle={toggle} selectedProduct={selectedProduct} />
            }
            
        </div>
    )
}
