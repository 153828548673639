import React from "react"
import PageHeader from "../components/Header"
import PageFooter from "../components/Footer"
import { useTranslation } from 'react-i18next'
import HarmonogramComponent from "../components/HarmonogramComponent"
import AdultsComponent from "../components/AdultsComponent"



const MasterCycle = () => {
    const {t} = useTranslation('translation')


    return (
        <div>
            <PageHeader />


            <div id="page_header" className="page-subheader site-subheader-cst">
                <div className="bgback" />
                <div className="th-sparkles" />
                <div className="kl-bg-source">
                    <div className="kl-bg-source__bgimage" style={{backgroundImage: 'url(images/general-bg-3.jpg)', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundPositionX: 'center', backgroundPositionY: 'center', backgroundSize: 'cover'}} />
                    <div className="kl-bg-source__overlay" style={{backgroundColor: 'rgba(53,53,53,0.1)'}} />
                </div>


                <div className="ph-content-wrap d-flex">
                <div className="container align-self-center">
                    <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="clearfix" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6" style={{backgroundColor: 'rgba(0,0,0,0.5)', padding: 15,}}>
                        <div className="subheader-titles">
                        <h2 className="subheader-maintitle">Master Cycle<sup>®</sup></h2>
                        <h4 className="subheader-subtitle">{t('page.advanced')}</h4>
                        </div>
                    </div>
                    </div>
                </div>
                </div>


                {/* <div className="kl-bottommask kl-bottommask--mask3">
                
                <svg width="2700px" height="57px" className="svgmask" viewBox="0 0 2700 57" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                    <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="filter-mask3">
                    <feOffset dx={0} dy={3} in="SourceAlpha" result="shadowOffsetInner1" />
                    <feGaussianBlur stdDeviation={2} in="shadowOffsetInner1" result="shadowBlurInner1" />
                    <feComposite in="shadowBlurInner1" in2="SourceAlpha" operator="arithmetic" k2={-1} k3={1} result="shadowInnerInner1" />
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.4 0" in="shadowInnerInner1" type="matrix" result="shadowMatrixInner1" />
                    <feMerge>
                        <feMergeNode in="SourceGraphic" />
                        <feMergeNode in="shadowMatrixInner1" />
                    </feMerge>
                    </filter>
                </defs>
                <path d="M-2,57 L-2,34.007 L1268,34.007 L1284,34.007 C1284,34.007 1291.89,33.258 1298,31.024 C1304.11,28.79 1329,11 1329,11 L1342,2 C1342,2 1345.121,-0.038 1350,-1.64313008e-14 C1355.267,-0.03 1358,2 1358,2 L1371,11 C1371,11 1395.89,28.79 1402,31.024 C1408.11,33.258 1416,34.007 1416,34.007 L1432,34.007 L2702,34.007 L2702,57 L1350,57 L-2,57 Z" className="bmask-bgfill" filter="url(#filter-mask3)" fill="#fbfbfb" />
                </svg>

              <i className="fas fa-angle-down" />
            </div> */}
            
            </div>


            <section className="hg_section hg_section--relative">
                {/*** <div className="kl-bg-source kl-bgSource-imageParallax js-KyHtmlParallax is-fixed is-visible" style={{height: 625, width: 1231, transform: 'translate3d(0px, 129.898px, 0px)'}}>
                    <div className="kl-bg-source__bgimage" style={{backgroundImage: 'url("images/photo-1431578500526-4d9613015464-resized.jpg")', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundPosition: '50% 50%', backgroundSize: 'cover', transform: 'translate3d(0px, -100px, 0px)'}} />
                </div>
                 ***/}
                <div className="container">
                    <div className="row">

                        <div className="col-sm-12 col-md-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="left-side">
                                        <img src="images/Master-Cycle-Lockup-Logo-COLOR.png" alt="The Master-Cycle® Program" />
                                        <br /><br /><br />

                                        {/* <h3 className="kl-font-alt fs-larger tcolor fw-bold title">                                             */}
                                            {/* <span className="fw-thin"> */}
                                            {/* <span className="fw-semibold tcolor"></span> */}
                                            {/* </span> */}
                                        {/* </h3> */}
                                        <p>{t('program.mc1')}</p>

                                        <p>{t('program.mc2')}</p>
                                        {/*** 
                                        <ul className="features">
                                            <li><p>BJJ je dizajnovane pre sebeobranu aj proti vacsiemu a silnejsiemu oponentovi.</p></li>
                                            <li><p>Nieje potreba ziadnich predchazajucich skusenosti s bojovim umenim</p></li>
                                        </ul>
                                        <a href="#" target="_blank" className="btn btn-fullcolor btn-third">BUY NOW</a> 
                                        ***/}
                                    </div>
                                </div>
                                
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}>
                                        <iframe title="mcp1" src="https://www.youtube.com/embed/fS54v9sCY3Y?rel=0&controls=0&showinfo=0" allowFullScreen />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>




            <section id="services" className="hg_section pt-10 pb-0">
            <div className="container">

                <div className="row mb-30">
                <div className="col-sm-12 col-md-12">
                    <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                    <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">
                    {t('program.mc3')}
                    </h3>
                    {/* 
                    <div className="tbk__symbol">
                        <span className="tbk__icon fs-xxl light-tcolor fas fa-ellipsis-h" />
                    </div> 
                    */}
                    {/* 
                    <h4 className="tbk__subtitle fs-m light-tcolor">
                        WE'RE GOOD AT SOME STUFF AND VERY GOOD AT OTHER STUFF
                    </h4> 
                    */}
                    <p>{t('program.mc4')}</p>
                    </div>
                </div>
                </div>

                <div className="row mb-30">
                <div className="col-sm-12 col-md-12">
                    <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                    <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">
                    {t('program.mc5')}
                    </h3>
                    <p>{t('program.mc6')}</p>
                    <img src="images/MC_chart.gif" alt="" />
                    </div>
                </div>
                </div>

                <div className="row mb-30">
                <div className="col-sm-12 col-md-12">
                    <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                    <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">
                    {t('program.mc7')}
                    </h3>
                    <p>{t('program.mc8')}</p>
                    </div>
                </div>
                </div>

                <div className="row mb-30">
                <div className="col-sm-12 col-md-12">
                    <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                    <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">
                    {t('program.mc9')}
                    </h3>
                    <p>{t('program.mc10')}</p>
                    </div>
                </div>
                </div>

                <div className="row mb-30">
                <div className="col-sm-12 col-md-12">
                    <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                    <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">
                    {t('program.mc11')}
                    </h3>
                    <p>{t('program.mc12')}</p>
                    </div>
                </div>
                </div>

                <div className="row mb-30">
                <div className="col-sm-12 col-md-12">
                    <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                    <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">
                    {t('program.mc13')}
                    </h3>
                    <p>{t('program.mc14')}</p>
                    </div>
                </div>
                </div>


            </div>
            </section>

            <AdultsComponent />
            <HarmonogramComponent category={["adults-pro"]} />


            <section id="services" className="hg_section">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12 col-md-12">
                        <div className="screenshot-box">
                            <div className="row">
    
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}>
                                    <iframe title="mcp2" src="https://www.youtube.com/embed/TSybO7VM9LI?rel=0&controls=0&showinfo=0" allowFullScreen />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}>
                                    <iframe title="mcp3" src="https://www.youtube.com/embed/_sH9ZYN84cc?rel=0&controls=0&showinfo=0" allowFullScreen />
                                    </div>
                                </div>
                            </div>    
                            <div className="row">

                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}>
                                    <iframe title="mcp4" src="https://www.youtube.com/embed/n0Eo74qbDww?rel=0&controls=0&showinfo=0" allowFullScreen />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}>
                                    <iframe title="mcp5" src="https://www.youtube.com/embed/dgxu-aa-CE0?rel=0&controls=0&showinfo=0" allowFullScreen />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
            </section>










            
            <PageFooter />
        </div>
    )
}

export default MasterCycle

