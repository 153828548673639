// import {Link} from "react-router-dom";

function Error404(props) {

    return (
        // <section className="content">
        //     <div className="error-page">
        //         <h2 className="headline text-warning"> 404</h2>
        //         <div className="error-content">
        //             <h3><i className="fas fa-exclamation-triangle text-warning" /> Oops! Page not found. </h3>
        //             <p>
        //                 We could not find the page you were looking for.
        //                 Meanwhile, you may <Link to={`/`}>Dashboard</Link>.
        //             </p>
        //         </div>
        //     </div>
        // </section>

        <div>

        <div id="page_header" className="page-subheader site-subheader-cst">
        <div className="bgback" />
        <div className="th-sparkles" />
        <div className="kl-bg-source">
            <div className="kl-bg-source__bgimage" style={{backgroundImage: 'url(images/general-bg-2.jpg)', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundPositionX: 'center', backgroundPositionY: 'center', backgroundSize: 'cover'}} />
            <div className="kl-bg-source__overlay" style={{backgroundColor: 'rgba(53,53,53,0.65)'}} />
        </div>
        </div>




        <section className="hg_section error404 pt-60 pb-100">
        <div className="container">
            <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                <div className="error404-content">
                {/* Title #1 */}
                <h2>
                    <span>404</span>
                </h2>
                {/*/ Title #1 */}
                {/* Title #2 */}
                <h3>
                    Stránka nebola nájdená.
                </h3>
                <h4>
                    Page not found.
                </h4>
                {/*/ Title #2 */}
                {/* Search box element */}
                <div className="elm-searchbox elm-searchbox--normal elm-searchbox--eff-typing mt-70">
                    {/* Search box wrapper */}
                    <div className="elm-searchbox__inner">
                    </div>
                    {/*/ Search box wrapper */}
                </div>
                {/*/ Search box element */}
                </div>
                {/*/ .error404-content */}
            </div>
            {/*/ col-sm-12 */}
            </div>
            {/*/ row */}
        </div>
        {/*/ container */}
        </section>

        </div>
    )
}
export default Error404