import React from "react"
import { useTranslation } from 'react-i18next'

const PageFooter = () => {
    const {t} = useTranslation('translation')

    return (

    <footer id="footer" data-footer-style={1}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-5 mb-30">
            {/* Title */}
            <h3 className="title m_title">
              BJJ Levice
            </h3>
            <div>
              <ul className="menu">
                <li style={{padding: "10px"}}><a href={t('url.gracie-combatives')}>Gracie Combatives<sup>®</sup><br />{ t('page.beginers') }</a></li>
                <li style={{padding: "10px"}}><a href={t('url.gracie-bullyproof')}>Gracie Bullyproof<sup>®</sup><br />{ t('page.kids') }</a></li>
                <li style={{padding: "10px"}}><a href={t('gracie-master-cycle')}>Master Cycle<sup>®</sup><br />{ t('page.advanced') }</a></li>
                <li style={{padding: "10px"}}><a href={t('url.private-class')}>{ t('page.private-class') }</a></li>
              </ul>
            </div>
          </div>
          {/*/ col-sm-12 col-md-5 mb-30 */}
          <div className="col-sm-12 col-md-4 mb-30">
            <div className="newsletter-signup">
              {/*
              <h3 className="title m_title">
                NEWSLETTER SIGNUP
              </h3>
              */}
              <img src="/images/CTC-300x283.png" alt="ctc" />
            </div>{/* end newsletter-signup */}
          </div>
          {/* col-sm-12 col-md-4 mb-30 */}
          <div className="col-sm-12 col-md-3 mb-30">
            {/* Title */}
            <h3 className="title m_title">
            { t('page.get-in-touch') }
            </h3>
            {/* Contact details */}
            <div className="contact-details">
              <p>
                <strong>{ t('phone') }:</strong>+421 918 107 782<br />
                <strong>Email:</strong> <a href="/#">info@bjjlevice.sk</a>
              </p>
              <p>
                <strong>BJJ Levice</strong><br />
                Holubyho 5, 93401 Levice <br />
              </p>
              <p>
                {/* <a href="http://goo.gl/maps/1OhOu" target="_blank" rel="noreferrer">
                  <i className="icon-map-marker white-icon" />
                  { t('open-in-google-maps') }
                </a> */}
              </p>
            </div>
            {/*/ .contact-details */}
          </div>
          {/*/ col-sm-12 col-md-3 mb-30 */}
        </div>
        {/*/ row */}

        {/*/ row */}
        <div className="row">
          <div className="col-sm-12">
            <div className="bottom clearfix">
              {/* social-icons */}
              <ul className="social-icons sc--clean clearfix">
                <li className="title">{t('social-media')}</li>
                <li><a href="https://www.facebook.com/graciejiujitsulevice/" target="_blank" rel="noreferrer" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
                <li><a href="https://www.instagram.com/graciejiujitsulevice/" target="_blank" rel="noreferrer" title="Instagram"><i className="fab fa-instagram" /></a></li>
                {/* <li><a href="https://www.youtube.com/@graciejiujitsulevice" target="_blank" rel="noreferrer" title="Youtube" ><i className="fab fa-youtube" /></a></li> */}
              </ul>
              {/*/ social-icons */}

              {/* copyright */}
              <div className="copyright">
                <a href="https://bjjlevice.sk">
                  <img src="/images/gjj-logo1.png" alt="BJJ Levice" style={{height: "70px", margin: "-25px 30px 0 0"}}/>
                </a>
                <p>
                  © 2023 {t('copyright')}. <a href="https://bjjlevice.sk" >BJJ Levice</a>.
                </p>
              </div>
              {/*/ copyright */}
            </div>
            {/*/ bottom */}
          </div>
          {/*/ col-sm-12 */}
        </div>
        {/*/ row */}
      </div>
      {/*/ container */}
    </footer>


    )

}

export default PageFooter


// import React from "react"
// import { useTranslation } from 'react-i18next'

// const PageFooter = () => {
//     const {t} = useTranslation('translation')

//     return (

//     <footer id="footer" data-footer-style={1}>
//       <div className="container">

//         <div className="row">
//           <div className="col-sm-12">
            
//             <div className=" clearfix"> {/* className="bottom clearfix" */}
//               {/* social-icons */}
//               <ul className="social-icons sc--clean clearfix">
//                 <li className="title">{t('social-media')}</li>
//                 <li><a href="https://www.facebook.com/bjjlevice/" target="_blank" rel="noreferrer" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
//                 <li><a href="https://www.instagram.com/bjjlevice/" target="_blank" rel="noreferrer" title="Instagram"><i className="fab fa-instagram" /></a></li>
//                 {/* <li><a href="https://www.youtube.com/@graciejiujitsulevice" target="_blank" rel="noreferrer" title="Youtube" ><i className="fab fa-youtube" /></a></li> */}
//               </ul>
//               {/*/ social-icons */}

//               {/* copyright */}
//               <div className="copyright">
//                 <a href="https://bjjlevice.sk">
//                   <img src="/images/bjjlevice-logo.png" alt="BJJ Levice" style={{height: "70px", margin: "-25px 30px 0 0"}}/>
//                 </a>
//                 <p>
//                   © 2023 {t('copyright')}. <a href="https://bjjlevice.sk" >BJJ Levice</a>.
//                 </p>
//               </div>
//               {/*/ copyright */}
//             </div>
//             {/*/ bottom */}
//           </div>
//           {/*/ col-sm-12 */}
//         </div>
//         {/*/ row */}
//       </div>
//       {/*/ container */}
//     </footer>


//     )

// }

// export default PageFooter
