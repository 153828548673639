import enMessages from "./en/index.json";
import skMessages from "./sk/index.json";

const translation = {
  en: {
    translation: enMessages
  },
  sk: {
    translation: skMessages
  }
}
export default translation 