import { combineReducers } from "redux";

import EshopCategories from "./categories/reducer"
import LanguageReducer from "./language/reducer"
import EshopProductsReducer from "./products/reducer";
import SummerCampReducer from "./summerCamp/reducer";

const rootReducer = combineReducers({
    EshopCategories,
    LanguageReducer,
    EshopProductsReducer,
    SummerCampReducer,
});

export default rootReducer;