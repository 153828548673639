import React from 'react'
// import categories from '../../data/eshop/categories.json'

export default function EshopCategories({categories}) {

    // console.log(categories)

    const handleEshopCategory = (category) => {
        console.log(category.id)
    }

    return (
        <div className="kl-store-page">

            <div className="widget">
                <h3 className="widgettitle title">
                  PRODUCT SEARCH
                </h3>
                <div className="widget_search">
                  <div className="search gensearch__wrapper">
                    {/* <form id="searchform-sidebar" className="gensearch__form" action="https://www.google.com/search" method="get" target="_blank">
                      <input id="s" name="q" maxLength={20} className="inputbox gensearch__input" type="text" size={20} defaultValue="SEARCH ..." onBlur="if (this.value=='') this.value='SEARCH ...';" onFocus="if (this.value=='SEARCH ...') this.value='';" /><button type="submit" id="searchsubmit-sidebar" value="go" className="gensearch__submit fas fa-search" />
                      <input type="hidden" id="sq" name="q" />
                    </form> */}
                  </div>
                </div>
              </div>


            <div id="kl-store_price_filter-2" className="widget kl-store widget_price_filter">
            <h3 className="widgettitle title">
                FILTER BY PRICE
            </h3>
            <form method="get" action="#">
                <div className="price-range">
                <div className="price-range-slider">
                </div>
                <button type="submit" className="button">Filter</button>
                <div className="pr-result">
                    <span>Price: </span>
                    <input type="text" className="price-result" data-currency="£" />
                </div>
                </div>
            </form>
            </div>

            <div id="kl-store_product_categories-2" className="widget kl-store widget_product_categories">
                <h3 className="widgettitle title">
                    PRODUCT CATEGORIES
                </h3>
                <ul className="product-categories">
                    { categories.map( (category, index) => 
                        <li className="cat-item" key={index} 
                            onClick={() => { const data = category; handleEshopCategory(data); }}
                        >
                            <a href="#">{category.name}</a><span className="count">({category.products.length})</span>
                        </li>                        
                    ) }
                </ul>
            </div>
            
            {/* <div id="kl-store_top_rated_products-2" className="widget kl-store widget_top_rated_products">
                <h3 className="widgettitle title">
                MOST RATED ITEMS
                </h3>
                <ul className="product_list_widget">
                <li>
                    <a href="product-page.html" title="Kallyas Fashion">
                    <img src="images/_shop/small1.jpg" className alt="Kallyas Fashion" title="Kallyas Fashion" />
                    <span className="product-title">
                        Natural fox fur collar
                    </span>
                    </a>
                    <div className="star-rating" title="Rated 5.00 out of 5">
                    <span style={{ width: '100%' }}>
                        <strong className="rating">5.00</strong> out of 5</span>
                    </div>
                    <span className="amount">
                    £219.00
                    </span>
                </li>
                <li>
                    <a href="product-page.html" title="Kallyas Fashion">
                    <img src="images/_shop/small2.jpg" className alt="Kallyas Fashion" title="Kallyas Fashion" />
                    <span className="product-title">
                        Natural fox fur collar
                    </span>
                    </a>
                    <div className="star-rating" title="Rated 5.00 out of 5">
                    <span style={{ width: '100%' }}>
                        <strong className="rating">5.00</strong> out of 5
                    </span>
                    </div>
                    <span className="amount">
                    £218.00
                    </span>
                </li>
                <li>
                    <a href="product-page.html" title="Kallyas Fashion">
                    <img src="images/_shop/small3.jpg" className alt="Kallyas Fashion" title="Kallyas Fashion" />
                    <span className="product-title">
                        Natural fox fur collar
                    </span>
                    </a>
                    <div className="star-rating" title="Rated 5.00 out of 5">
                    <span style={{ width: '100%' }}>
                        <strong className="rating">5.00</strong> out of 5
                    </span>
                    </div>
                    <span className="amount">£120.00</span>
                </li>
                </ul>
            </div> */}
        </div>
    )
}
