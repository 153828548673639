import React from "react"
import PageHeader from "../components/Header"
// import UserDetail from "../components/UserDetail"
// import PageSlider from "../components/Slider"
// import WelcomePage from "../components/WelcomePage"
// import Selector from "../components/Selector"
// import FreeTrial from "../components/FreeTrial"
import PageFooter from "../components/Footer"
// import SummerCampComponent from "../components/SummerCampComponent"
import NaborFormular from "../components/NaborFormular"
import KidsComponent from "../components/KidsComponent"
import PhotoAlbumComponent from "../components/PhotoAlbumComponent"
import HeaderImage from "../components/HeaderImage"
import HarmonogramComponent from "../components/HarmonogramComponent"


const Kids = () => {
    // const dispatch = useDispatch()
    // const { userProfile } = useSelector((state) => state)
    // const debug = JSON.stringify(userProfile, null, 2)

    // const [hasRoles, setHasRoles] = useState(false)
    // const [hasAwards, setHasAwards] = useState(false)
    // const [hasAdultRanks, setHasAdultRanks] = useState(false)
    
    // useEffect(() => {
    //     if (userProfile.roles) { setHasRoles(true) }
    //     if (userProfile.awards) { setHasAwards(true) }
    //     if (userProfile.adultRanks) { setHasAdultRanks(true) }
    // }, [userProfile])
    

    return (
        <div>
            <PageHeader />
            <HeaderImage maintitle="BJJ pre deti" subtitle="do 12 rokov" img="images/general-bg-kids.jpg" />
            <KidsComponent />
            <HarmonogramComponent category={["kids"]} />
            <PhotoAlbumComponent />
            <NaborFormular />
            <PageFooter />
        </div>
    )
}

export default Kids

