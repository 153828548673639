import React from "react"
import PageHeader from "../components/Header"
// import UserDetail from "../components/UserDetail"
// import PageSlider from "../components/Slider"
// import WelcomePage from "../components/WelcomePage"
// import Selector from "../components/Selector"
// import FreeTrial from "../components/FreeTrial"
import PageFooter from "../components/Footer"
import Kontakt from "../components/Kontakt"
import HeaderImage from "../components/HeaderImage"

const Kontakty = () => {
    // const dispatch = useDispatch()
    // const { userProfile } = useSelector((state) => state)
    // const debug = JSON.stringify(userProfile, null, 2)

    // const [hasRoles, setHasRoles] = useState(false)
    // const [hasAwards, setHasAwards] = useState(false)
    // const [hasAdultRanks, setHasAdultRanks] = useState(false)
    


    return (
        <div>
            <PageHeader />
            
<div className="kl-slideshow static-content__slideshow scontent__maps">
  {/* Map with custom height */}
  <div className="th-google_map" style={{height: 750}}>
  </div>
  {/* end map */}
  {/* Google map content panel */}
  <div className="kl-contentmaps__panel">
    {/* <a href="#" className="js-toggle-class kl-contentmaps__panel-tgg hidden-xs" data-target=".kl-contentmaps__panel" data-target-class="is-closed" /> */}
    {/* Image & Image pop-up */}
    <a href="#" data-lightbox="image" className="kl-contentmaps__panel-img" style={{backgroundImage: 'url(images/home-office-569359_640.jpg)'}} />
    {/* Content */}
    <div className="kl-contentmaps__panel-info">
      {/* Title */}
      <h5 className="kl-contentmaps__panel-title">
        BJJ Levice
      </h5>
      {/* Description */}
      <div className="kl-contentmaps__panel-info-text">
        <p>
        {/* Vybuduj dôveru v seba samého, nauč sa zvládať a riešiť konfliktné situácie, viesť ich k vzájomnej spolupráci, disciplíne, odvahe i ovládaniu emócii. */}
        škola Brazílske Jiu Jiutsu pre deti a dospelých
        </p>
      </div>
    </div>
    {/*/ Content */}
  </div>
  {/* Google map content panel */}
  {/* Bottom mask style 5 */}
  <div className="kl-bottommask kl-bottommask--mask5">
    <svg width="2700px" height="64px" className="svgmask" viewBox="0 0 2700 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="filter-mask5">
          <feOffset dx={0} dy={2} in="SourceAlpha" result="shadowOffsetInner1" />
          <feGaussianBlur stdDeviation="1.5" in="shadowOffsetInner1" result="shadowBlurInner1" />
          <feComposite in="shadowBlurInner1" in2="SourceAlpha" operator="arithmetic" k2={-1} k3={1} result="shadowInnerInner1" />
          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.45 0" in="shadowInnerInner1" type="matrix" result="shadowMatrixInner1" />
          <feMerge>
            <feMergeNode in="SourceGraphic" />
            <feMergeNode in="shadowMatrixInner1" />
          </feMerge>
        </filter>
      </defs>
      <path d="M1892,0 L2119,44.993 L2701,45 L2701.133,63.993 L-0.16,63.993 L1.73847048e-12,45 L909,44.993 L1892,0 Z" className="bmask-bgfill" fill="#fbfbfb" filter="url(#filter-mask5)" />
      <path d="M2216,44.993 L2093,55 L1882,6 L995,62 L966,42 L1892,0 L2118,44.993 L2216,44.993 L2216,44.993 Z" fill="#cd2122" className="bmask-customfill" filter="url(#filter-mask5)" />
    </svg>
  </div>
  {/*/ Bottom mask style 5 */}
</div>


            {/* <HeaderImage maintitle="Kontaktné" subtitle="informácie" img="images/general-bg-adults1.png" /> */}
            <Kontakt />
            <PageFooter />
        </div>
    )
}

export default Kontakty
