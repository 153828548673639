import React from "react"
//import {Link} from "react-router-dom";
import { t } from "i18next"
// import './custom.css'
// import { useTranslation } from 'react-i18next'
import { send } from 'emailjs-com'
import ReCAPTCHA from "react-google-recaptcha"
import {NotificationManager} from 'react-notifications'
import * as Yup from "yup"
import { Formik, Form, Field, ErrorMessage } from "formik"



const Kontakt = () => {
  const captchaRef = React.useRef()

  const defaultValues = {
      name: '',
      lastname: '',
      email: '',
      subject: '',
      message: '',
  }

  const onSubmit = (values) => {
      const token = captchaRef.current.getValue()
      send(
          'service_roxp2n8',
          'template_nr0uryl',
          {
              ...values,
              'g-recaptcha-response': token,
          },
          'i0xGfmqONO5OrVi-a'
      )
      .then((response) => {
          console.log('SUCCESS!', response.status, response.text)
          NotificationManager.success('Success message', 'Email was successfuly sent.', 3000);
      })
      .catch((err) => {
          console.log('FAILED...', err)
          NotificationManager.error('Warning message', 'Email was not sent. Some internal error', 3000);
      })


  }

  const validationSchema = Yup.object({
      name: Yup.string().required(),
      lastname: Yup.string().required(),
      email: Yup.string().email().required(),
      subject: Yup.string().required(),
      message: Yup.string().optional(),
  })

    return (

<div>



<section id="kontakt" className="hg_section--relative bg-white pt-0 pb-30">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="kl-title-block clearfix text-center tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                  {/* <h3 className="tbk__title kl-font-alt fs-larger gray fw-bold">
                    Kontakty
                  </h3> */}
                  {/* <div className="tbk__symbol">
                    <span className="tbk__icon fs-xxl light-gray fas fa-ellipsis-h" />
                  </div> */}
                  {/* 
                  <h4 className="tbk__subtitle fs-m light-gray">
                    WE'RE GOOD AT SOME STUFF AND VERY GOOD AT OTHER STUFF
                  </h4>
                  */}
                </div>


              {/* Title element */}
              <div className="kl-title-block clearfix text-left tbk-symbol-- tbk-icon-pos--after-title">
                {/* Title with bold style */}
                <h3 className="tbk__title fw-bold">
                  BJJ Levice
                </h3>
                {/* Sub-title with semibold style */}
                {/* <h4 className="tbk__subtitle fw-semibold">
                BJJ Levice
                </h4> */}
              </div>
              {/*/ Title element */}
            </div>
            {/*/ col-md-12 col-sm-12 */}
            <div className="col-sm-12 col-md-4 col-sm-4">
              {/* Text box */}
              <div className="text_box">
                <p>
                  <strong><em>Holubyho 5, 93401 Levice</em></strong><br />
                  
                </p>
              </div>
              {/*/ Text box */}
            </div>
            {/*/ col-sm-12 col-md-4 col-sm-4 */}
            <div className="col-sm-12 col-md-4 col-sm-4">
              {/* Text box */}
              <div className="text_box">
                <p>
                  <strong>Tel: 0918 107 782</strong>
                </p>
              </div>
              {/*/ Text box */}
            </div>
            {/*/ col-sm-12 col-md-4 col-sm-4 */}
            <div className="col-sm-12 col-md-4 col-sm-4">
              {/* Text box */}
              <div className="text_box">
                <p>
                  <strong>Email: info@bjjlevice.sk</strong><br />
                  Web: www.bjjlevice.sk
                </p>
              </div>
              {/*/ Text box */}
            </div>
            {/*/ col-sm-12 col-md-4 col-sm-4 */}
          </div>
          {/*/ row */}
        </div>
        {/*/ container */}
        {/* Bottom mask shadow simple down style */}
        <div className="kl-bottommask kl-bottommask--shadow_simple_down">
        </div>
        {/*/ Bottom mask shadow simple down style */}
      </section>

  
  {/* Contact form element & details section with custom paddings */}
  <section className="hg_section pt-80 pb-80">
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-9 col-lg-9 mb-sm-30">
          {/* Contact form element */}
          <div className="contactForm">
            <Formik initialValues={defaultValues} validationSchema={validationSchema} onSubmit={async (values, { resetForm }) => { await onSubmit(values); resetForm(); }} >
            <Form className="contact_form row">

            {/* <form action="php_helpers/_contact-process.php" method="post" className="contact_form row" encType="multipart/form-data"> */}
              {/* Response wrapper */}
              <div className="cf_response" />
              <div className="col-sm-6 kl-fancy-form">
                {/* <input type="text" name="name" id="cf_name" className="form-control" placeholder="Please enter your first name" defaultValue tabIndex={1} maxLength={35} required /> */}
                <Field
                    name="name"
                    id="cf_name" 
                    className="form-control" 
                    placeholder={t('yourFirstname')}
                    tabIndex={1} 
                    maxLength={35} 
                    required
                    type="text"                    
                />
                <div className="text-danger"><strong><ErrorMessage name="name" /></strong></div>                            

                <label className="control-label">
                  {t('firstname').toUpperCase()}
                </label>
              </div>
              <div className="col-sm-6 kl-fancy-form">
                {/* <input type="text" name="lastname" id="cf_lastname" className="form-control" placeholder="Please enter your first last name" defaultValue tabIndex={1} maxLength={35} required /> */}
                <Field
                    name="lastname"
                    id="cf_lastename" 
                    className="form-control" 
                    placeholder={t('yourLastname')}                  
                    tabIndex={1} 
                    maxLength={35} 
                    required
                    type="text"                    
                />
                <div className="text-danger"><strong><ErrorMessage name="lastname" /></strong></div>                            
                <label className="control-label">
                {t('lastname').toUpperCase()}
                </label>
              </div>
              <div className="col-sm-12 kl-fancy-form">
                {/* <input type="text" name="email" id="cf_email" className="form-control h5-email" placeholder="Please enter your email address" defaultValue tabIndex={1} maxLength={35} required /> */}
                <Field
                    name="email"
                    id="cf_email" 
                    className="form-control h5-email" 
                    placeholder={t('yourEmail')}
                    tabIndex={1} 
                    maxLength={50} 
                    required
                    type="text"                    
                />
                <div className="text-danger"><strong><ErrorMessage name="email" /></strong></div>                            

                <label className="control-label">
                {t('email').toUpperCase()}
                </label>
              </div>
              <div className="col-sm-12 kl-fancy-form">
                {/* <input type="text" name="subject" id="cf_subject" className="form-control" placeholder="Enter the subject message" defaultValue tabIndex={1} maxLength={35} required /> */}
                <Field
                    name="subject"
                    id="cf_subject" 
                    className="form-control" 
                    placeholder={t('yourSubject')}
                    tabIndex={1} 
                    maxLength={50} 
                    required
                    type="text"                    
                />
                <div className="text-danger"><strong><ErrorMessage name="subject" /></strong></div>                            

                <label className="control-label">
                {t('subject').toUpperCase()}
                </label>
              </div>
              <div className="col-sm-12 kl-fancy-form">
                <Field component="textarea" name="message" id="cf_message" className="form-control" cols={30} rows={10} placeholder={t('yourMessage')} tabIndex={4} />
                <div className="text-danger"><strong><ErrorMessage name="message" /></strong></div>                            
                <label className="control-label">
                {t('message').toUpperCase()}
                </label>
              </div>
              <div className="col-sm-6">
                <ReCAPTCHA
                    sitekey="6LdiYGAkAAAAAB896FlVk3v6yxe9A5-3YTgrFtXF"
                    ref={captchaRef}
                />                            
              </div>
              <div className="col-sm-6 text-right">
                {/* Contact form send button */}
                <button className="btn btn-fullcolor" type="submit">
                {t('submit')}
                </button>
              </div>
            {/* </form> */}
            </Form>
            </Formik>

          </div>
          {/*/ Contact form element */}
        </div>
        {/*/ col-sm-12 col-md-9 col-lg-9 mb-sm-30 */}
        <div className="col-sm-12 col-md-3 col-lg-3">
          {/* Contact details */}
          <div className="text_box">
            {/* Title */}
            <h3 className="text_box-title text_box-title--style2">
              BJJ Levice
            </h3>
            <br />
            {/* Sub-title */}
            <i className="fa fa-envelope"></i>
            <h4>Holubyho 5, <br />93401 Levice</h4>
            {/* Description */}
            <p>
            <br />
            <strong>IBAN:</strong> <br />
            SK68 8330 0000 0026 0259 1804
            </p>
            <br />
            <p>
              <i className="fa fa-at"></i> <a href="mailto:mailto://info@bjjlevice.sk">info@bjjlevice.sk</a><br />
              <i className="fa fa-link"></i> <a href="https://www.bjjlevice.sk/">https://www.bjjlevice.sk</a>
            </p>
          </div>
          {/*/ Contact details */}
        </div>
        {/*/ col-sm-12 col-md-3 col-lg-3 */}
      </div>
      {/*/ .row */}
    </div>
    {/*/ .container */}
  </section>
  {/*/ Contact form element & details section with custom paddings */}
</div>

    )
}
export default Kontakt