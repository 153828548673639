import React from "react"
// import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'

function TopQuestions(props) {
  const {t} = useTranslation('translation')

    return (
        <div>

{/* 
        <div id="page_header" className="page-subheader site-subheader-cst">
        <div className="bgback" />
        <div className="th-sparkles" />
        <div className="kl-bg-source">
            <div className="kl-bg-source__bgimage" style={{backgroundImage: 'url(images/general-bg-6.jpg)', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundPositionX: 'center', backgroundPositionY: 'center', backgroundSize: 'cover'}} />
            <div className="kl-bg-source__overlay" style={{backgroundColor: 'rgba(53,53,53,0.1)'}} />
        </div>


        <div className="ph-content-wrap d-flex">
                <div className="container align-self-center">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="clearfix" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="subheader-titles" style={{backgroundColor: 'rgba(0,0,0,0.5)', padding: 15,}}>
                        <h2 className="subheader-maintitle">{t('page.scheduler')}</h2>
                        <h4 className="subheader-subtitle">CLASS SCHEDULE</h4>
                    </div>
                    </div>
                </div>
                </div>
            </div>


        </div> */}


        <section id="top10" className="hg_section pb-100">
        <div className="container">
            <div className="row">

            <div className="col-sm-12 col-md-12">

              <div className="kl-title-block clearfix text-center tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                  <h3 className="tbk__title kl-font-alt fs-larger gray fw-bold">
                    TOP 10 najčastejsie kladených otázok ako začať
                  </h3>
                  <div className="tbk__symbol">
                    <span className="tbk__icon fs-xxl light-gray fas fa-ellipsis-h" />
                  </div>
                  {/* 
                  <h4 className="tbk__subtitle fs-m light-gray">
                    WE'RE GOOD AT SOME STUFF AND VERY GOOD AT OTHER STUFF
                  </h4>
                  */}
                </div>



                <div className="step-boxes-3">

                <div className="process_box" data-align="left">
                    <div className="number">
                    <span>01</span>
                    </div>
                    <div className="content">
                    <h4 className="stp_title">
                    {t('q.q1')}
                    </h4>
                    <p>
                    {t('q.a1')}                    
                    </p>
                    </div>
                    <div className="clear">
                    </div>
                </div>

                <div className="process_box" data-align="right">
                    <div className="number">
                    <span>02</span>
                    </div>
                    <div className="content">
                    <h4 className="stp_title">
                    {t('q.q2')}
                    </h4>
                    <p>
                    {t('q.a2')}
                    </p>
                    </div>
                    <div className="clear">
                    </div>
                </div>

                <div className="process_box" data-align="left">
                    <div className="number">
                    <span>03</span>
                    </div>
                    <div className="content">
                    <h4 className="stp_title">
                    {t('q.q3')}
                    </h4>
                    <p>
                    {t('q.a3')}
                    </p>
                    </div>
                    <div className="clear">
                    </div>
                </div>

                <div className="process_box" data-align="right">
                    <div className="number">
                    <span>04</span>
                    </div>
                    <div className="content">
                    <h4 className="stp_title">
                    {t('q.q4')}
                    </h4>
                    <p>
                    {t('q.a4')}
                    </p>
                    </div>
                    <div className="clear">
                    </div>
                </div>

                <div className="process_box" data-align="left">
                    <div className="number">
                    <span>05</span>
                    </div>
                    <div className="content">
                    <h4 className="stp_title">
                    {t('q.q5')}
                    </h4>
                    <p>
                    {t('q.a5')}
                    </p>
                    </div>
                    <div className="clear">
                    </div>
                </div>


                <div className="process_box" data-align="right">
                    <div className="number">
                    <span>06</span>
                    </div>
                    <div className="content">
                    <h4 className="stp_title">
                    {t('q.q6')}
                    </h4>
                    <p>
                    {t('q.a6')}
                    </p>
                    </div>
                    <div className="clear">
                    </div>
                </div>

                <div className="process_box" data-align="left">
                    <div className="number">
                    <span>07</span>
                    </div>
                    <div className="content">
                    <h4 className="stp_title">
                    {t('q.q7')}
                    </h4>
                    <p>
                    {t('q.a7')}
                    </p>
                    </div>
                    <div className="clear">
                    </div>
                </div>

                <div className="process_box" data-align="right">
                    <div className="number">
                    <span>08</span>
                    </div>
                    <div className="content">
                    <h4 className="stp_title">
                    {t('q.q8')}
                    </h4>
                    <p>
                    {t('q.a8')}
                    </p>
                    </div>
                    <div className="clear">
                    </div>
                </div>

                <div className="process_box" data-align="left">
                    <div className="number">
                    <span>09</span>
                    </div>
                    <div className="content">
                    <h4 className="stp_title">
                    {t('q.q9')}
                    </h4>
                    <p>
                    {t('q.a9')}
                    </p>
                    </div>
                    <div className="clear">
                    </div>
                </div>

                <div className="process_box last" data-align="right">
                    <div className="number">
                    <span>10</span>
                    </div>
                    <div className="content">
                    <h4 className="stp_title">
                    {t('q.q10')}
                    </h4>
                    <p>
                    {t('q.a10')}
                    </p>
                    </div>
                    <div className="clear">
                    </div>
                </div>

                </div>
            </div>

            </div>
        </div>
        </section>





        </div>
    )
}
export default TopQuestions

