import { SUCCESS_SUFFIX, FAIL_SUFFIX } from "redux-axios-middleware"

import {
  GET_SUMMERCAMP_EVENTS,
  REGISTER_SUMMERCAMP_EVENTS,
} from "./actionType";

const INIT_STATE = {
  summerCampsEvents: [],
  error: {},
  // isCategorySuccess: false
}

const SummerCampReducer = (state = INIT_STATE, action) => {  

  switch (action.type) {
    case GET_SUMMERCAMP_EVENTS + SUCCESS_SUFFIX:
      return {
        ...state,
        summerCampsEvents: action.payload.data,
      }

    case GET_SUMMERCAMP_EVENTS + FAIL_SUFFIX:
      return {
        ...state,
        error: action.payload
      }

    case REGISTER_SUMMERCAMP_EVENTS + SUCCESS_SUFFIX:
      return {
        ...state,
      }

    case REGISTER_SUMMERCAMP_EVENTS + FAIL_SUFFIX:
      return {
        ...state,
        error: action.payload
      }
  

    default:
      return state;
  }

}
export default SummerCampReducer
