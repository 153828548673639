import { SUCCESS_SUFFIX, FAIL_SUFFIX } from "redux-axios-middleware"

import {
  GET_ESHOP_CATEGORIES,  
} from "./actionType";

const INIT_STATE = {
  eshopCategories: [],
  error: {},
  isCategorySuccess: false
}

const EshopCategories = (state = INIT_STATE, action) => {  

  switch (action.type) {
    case GET_ESHOP_CATEGORIES + SUCCESS_SUFFIX:
      return {
        ...state,
        isCategorySuccess: true,
        eshopCategories: action.payload.data,
      }

    case GET_ESHOP_CATEGORIES + FAIL_SUFFIX:
      return {
        ...state,
        isCategorySuccess: false,
        error: action.payload
      }
    default:
      return state;
  }

}
export default EshopCategories
