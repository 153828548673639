import React from 'react'
import {
    // Row,
    // Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    // FormFeedback,
    // Label,
    // Input,
    // Form, 
    
  } from "reactstrap"

export default function ModalProductDetail({toggle, modal, setModal, selectedProduct}) {
  return (
    <Modal size="xl" id="showModal" isOpen={modal} toggle={toggle} >
    <ModalHeader className="bg-soft-info p-3" toggle={toggle}>
    </ModalHeader>

        <ModalBody className="pb-30">
        <div className="product">
                <div className="row product-page">
                    <div className="single_product_main_image col-sm-12 col-md-5 col-lg-5 mb-sm-40">
                        <div className="hg_badge_container">
                            {selectedProduct.tag_sale !== null &&
                                <span className="hg_badge_sale">
                                    {selectedProduct.tag_sale}
                                </span>
                            }
                            {selectedProduct.tag_new !== null &&
                                <span class="hg_badge_new">NEW!
                                    {selectedProduct.tag_new}
                                </span>
                            }
                        </div>

                        {/* Images */}
                        <div className="images">
                            <a href="images/_shop/model2.jpg" className="kl-store-main-image zoom" title="Kallyas Fashion">
                                <img src="images/_shop/model2.jpg"  alt="Kallyas Fashion" title="Kallyas Fashion" />
                            </a>
                            <div className="thumbnails columns-4">
                                <a href="images/_shop/model2.jpg" className="zoom first" title="Kallyas Fashion">
                                    <img src="images/_shop/model2small.jpg"  alt="Kallyas Fashion" title="Kallyas Fashion" />
                                </a>
                                <a href="images/_shop/model2a.jpg" className="zoom" title="Kallyas Fashion">
                                    <img src="images/_shop/model2asmall.jpg"  alt="Kallyas Fashion" title="Kallyas Fashion" />
                                </a>
                                <a href="images/_shop/model2.jpg" className="zoom first" title="Kallyas Fashion">
                                    <img src="images/_shop/model2small.jpg"  alt="Kallyas Fashion" title="Kallyas Fashion" />
                                </a>
                                <a href="images/_shop/model2a.jpg" className="zoom" title="Kallyas Fashion">
                                    <img src="images/_shop/model2asmall.jpg"  alt="Kallyas Fashion" title="Kallyas Fashion" />
                                </a>
                            </div>                            
                        </div>
                        {/*/ Images */}

                    </div>

                    <div className="main-data col-sm-12 col-md-7 col-lg-7">
                        <div className="summary entry-summary">                            
                            <h2 className="product_title entry-title">
                                {selectedProduct.title}
                            </h2>
                            <div>
                                <p className="price">
                                    <del data-was="WAS">
                                        <span className="amount">{selectedProduct.regular_price} &euro;</span>
                                    </del>
                                    <ins data-now="NOW">
                                        <span className="amount">{selectedProduct.saler_price}</span>
                                    </ins>
                                </p>
                            </div>
                            <div>
                                <p className="desc kw-details-desc">
                                    {selectedProduct.short_description}
                                </p>
                            </div>
                            <table className="variations">
                                <tbody>
                                    <tr>
                                        <td className="label">
                                            <label htmlFor="pa_color">color</label>
                                        </td>
                                        <td className="value">
                                            { selectedProduct.colors &&

                                            <select id="pa_color" className="attribute_pa_color" data-attribute_name="attribute_pa_color">
                                                <option value>Choose an option</option>
                                                { selectedProduct.colors.map( (color, index) =>
                                                    <option value>{color}</option>
                                                )}

                                            </select>
                                        }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/*/ Variations */}
                            {/* Single variations wrapper */}
                            <div className="single_variation_wrap">
                                {/* Price variation */}
                                <div className="single_variation">
                                    <span className="price">
                                        <del>
                                            <span className="amount">£3,499.00</span>
                                        </del>
                                        <ins>
                                            <span className="amount">£2,499.00</span>
                                        </ins>
                                    </span>
                                </div>
                                {/*/ Price variation */}
                                {/* Button variations */}
                                <div className="variations_button">
                                    <div className="quantity">
                                        {/* <input type="number" step={1} name="quantity" defaultValue={1} title="Qty" className="input-text qty text" size={4} min={1} /> */}
                                    </div>
                                    <button type="submit" className="single_add_to_cart_button button alt">Add to cart</button>
                                </div>
                                {/*/ Button variations */}
                            </div>
                            {/*/ Single variations wrapper */}

                            {/* Cart */}
                            {/* Meta */}
                            <div className="product_meta">
                                <span className="sku_wrapper">SKU: <span className="sku">N/A</span></span>
                                <span className="posted_in">Categories: <a href="product-category.html" rel="tag">CLOTHING</a>, <a href="product-category.html" rel="tag">FURS</a></span>
                            </div>
                            {/*/ Meta */}
                        </div>
                        {/* .summary */}
                    </div>
                    {/*/ main-data col-sm-12 col-md-7 col-lg-7 */}
                </div>
                {/*/ row product-page */}
                {/* Description & Reviews tabs */}
                <div className="tabbable">
                    {/* Navigation */}
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                            <a href="#tab-description" className="nav-link active" data-toggle="tab">
                                Description
                            </a>
                        </li>
                        {/* <li className="nav-item">
                                          <a href="#tab-reviews" className="nav-link" data-toggle="tab">
                                              Reviews (3)
                                          </a>
                                      </li> */}
                    </ul>
                    {/* Tab content */}
                    <div className="tab-content">
                        {/* Description */}
                        <div className="tab-pane fade show active" id="tab-description" role="tabpanel">
                            <h2 className="fs-s mb-15">
                                PRODUCT DESCRIPTION
                            </h2>
                            <p>
                                The essence of Kallyas signature style lies in a long heritage of manufacturing and creating themes &amp; templates collections and our designer’s ability to find inspiration in the millennial artistic world.
                            </p>
                        </div>
                        {/*/ Description */}
                        {/* Reviews */}
                        {/* <div className="tab-pane fade" id="tab-reviews" role="tabpanel">
                                          <div id="reviews">
                                              <div id="comments">
                                                  <h2 className="fs-s mb-15">
                                                      3 REVIEWS FOR HOODIE WITH PATCH LOGO
                                                  </h2>
                                                  <ol className="commentlist">
                                                      <li className="comment even thread-even depth-1">
                                                          <div id="comment-13">
                                                              <div className="comment_container clearfix">
                                                                  <img alt src="http://1.gravatar.com/avatar/7a6df00789e50714fcde1b759befcc84?s=60&d=mm&r=g" srcSet="http://1.gravatar.com/avatar/7a6df00789e50714fcde1b759befcc84?s=120&d=mm&r=g 2x" className="avatar avatar-60 photo" height={60} width={60} />
                                                                  <div className="comment-text">
                                                                      <p className="meta">
                                                                          <strong>Stuart</strong> – January 7, 2018:
                                                                      </p>
                                                                      <div className="description">
                                                                          <p>
                                                                              Another great quality product that anyone who see’s me wearing has asked where to purchase one of their own.
                                                                          </p>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </li>
                                                      <li className="comment odd alt thread-odd thread-alt depth-1">
                                                          <div id="comment-14">
                                                              <div className="comment_container clearfix">
                                                                  <img alt src="http://1.gravatar.com/avatar/4cd8db29cac75b73697e8bbe9da98f5d?s=60&d=mm&r=g" srcSet="http://1.gravatar.com/avatar/4cd8db29cac75b73697e8bbe9da98f5d?s=120&d=mm&r=g 2x" className="avatar avatar-60 photo" height={60} width={60} />
                                                                  <div className="comment-text">
                                                                      <p className="meta">
                                                                          <strong>Ryan</strong> – January 7, 2018:
                                                                      </p>
                                                                      <div className="description">
                                                                          <p>
                                                                              This hoodie gets me lots of looks while out in public, I got the blue one and it’s awesome. Not sure if people are looking at my hoodie only, or also at my rocking bod.
                                                                          </p>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </li>
                                                      <li className="comment even thread-even depth-1">
                                                          <div id="comment-15">
                                                              <div className="comment_container clearfix">
                                                                  <img alt src="http://2.gravatar.com/avatar/59c82b1d2c60537f900fb191b3cb611b?s=60&d=mm&r=g" srcSet="http://2.gravatar.com/avatar/59c82b1d2c60537f900fb191b3cb611b?s=120&d=mm&r=g 2x" className="avatar avatar-60 photo" height={60} width={60} />
                                                                  <div className="comment-text">
                                                                      <p className="meta">
                                                                          <strong>Maria</strong> – January 9, 2018:
                                                                      </p>
                                                                      <div className="description">
                                                                          <p>
                                                                              Ship it!
                                                                          </p>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </li>
                                                  </ol>
                                              </div>
                                              <div id="review_form_wrapper">
                                                  <div id="review_form">
                                                      <div id="respond" className="comment-respond">
                                                          <h3 id="reply-title" className="comment-reply-title">Add a review <small><a rel="nofollow" id="cancel-comment-reply-link" href="#">Cancel reply</a></small></h3>
                                                          <p className="must-log-in">
                                                              You must be <a href="my-account.html">logged in</a> to post a review.
                                                          </p>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="clear">
                                              </div>
                                          </div>
                                      </div> */}
                        {/*/ Reviews */}
                    </div>
                    {/*/ Tab content */}
                </div>
                {/* Description & Reviews tabs */}

            </div>
        


        </ModalBody>
        <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
            <button type="button" className="btn btn-light" onClick={() => { setModal(false); }} > Close </button>
            {/* <button type="submit" className="btn btn-success" id="add-btn"> {!!isEdit ? "Update" : "Add Payments"} </button> */}
        </div>
        </ModalFooter>
    </Modal>

  )
}