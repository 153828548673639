// import {Link} from "react-router-dom";


function Tendaystrial(props) {

    return (
      <section id="10daystrial" className="hg_section pt-80 pb-80" style={{backgroundColor: "#fff"}}> 
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">

              <div className="kl-title-block clearfix text-center tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                  <h3 className="tbk__title kl-font-alt fs-larger gray fw-bold">
                    Nezáväzné vyskúšanie našej hodiny
                  </h3>
                  <div className="tbk__symbol">
                    <span className="tbk__icon fs-xxl light-gray fas fa-ellipsis-h" />
                  </div>
                  
                  <h4 className="tbk__subtitle fs-m light-gray">
                  Všetky lekcie programu pre začiatočníkov môžete navštevovať bez obmedzenia.<br/>
                  Sme presvedčení, že štruktúra výučby, vzájomná priateľská interakcia a kvalitná výučba vás presvedčia.<br/>
                  Ak to nie je presne to, čo hľadáte, nebudeme vám nič účtovať - takže nemáš čo stratiť
                  </h4>
                 
                </div>


            </div>
          </div>
        </div>
      </section>

    )
}
export default Tendaystrial