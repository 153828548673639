// import {Link} from "react-router-dom";


function AdultsComponent(props) {

    return (
        // <section className="content">
        //     <div className="error-page">
        //         <h2 className="headline text-warning"> 404</h2>
        //         <div className="error-content">
        //             <h3><i className="fas fa-exclamation-triangle text-warning" /> Oops! Page not found. </h3>
        //             <p>
        //                 We could not find the page you were looking for.
        //                 Meanwhile, you may <Link to={`/`}>Dashboard</Link>.
        //             </p>
        //         </div>
        //     </div>
        // </section>

        <div>




  <section className=" pt-80 pb-80">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 col-md-12">
        <div className="kl-title-block">
          <h3 className="tbk__title fs-xxl fw-thin">
            <span className="fw-semibold fs-xxxl fs-xs-xl tcolor">Ceny</span><small> ( od 13+ rokov )</small>
          </h3>
        </div>
      </div>
      <div className="col-sm-12 col-md-12">
        <div className="pricing-table-element" data-columns={3}>


          <div className="plan-column ">
            <ul>
              <li className="plan-title">
                <div className="inner-cell">
                
                </div>
              </li>
              {/* <li className="subscription-price">
                <div className="inner-cell">
                  <span className="currency">$</span>
                  <span className="price">9.99</span>
                  per month
                </div>
              </li> */}
              <li>
                <div className="inner-cell text-left pl-20 pr-20">
                <strong>Začiatočnícky program</strong> <br />( 8 hodín mesačne )
                </div>
              </li>
              <li>
                <div className="inner-cell text-left pl-20 pr-20">
                <strong>Začiatočnícky program</strong> <br />( neobmedzený počet hodín mesačne )
                </div>
              </li>
              <li>
                <div className="inner-cell text-left pl-20 pr-20">
                <strong>Program pre pokročilých</strong> <br />( neobmedzený počet hodín mesačne )
                </div>
              </li>
              <li>
                <div className="inner-cell text-left pl-20 pr-20">
                <strong>Permanentka 10 vstupov</strong>
                </div>
              </li>
              <li>
                <div className="inner-cell text-left pl-20 pr-20">
                <strong>Súkromná hodina</strong>
                </div>
              </li>
            </ul>
          </div>

          <div className="plan-column ">
            <ul>
              <li className="plan-title">
                <div className="inner-cell">
                cenník
                </div>
              </li>
              {/* <li className="subscription-price">
                <div className="inner-cell">
                  <span className="currency">$</span>
                  <span className="price">9.99</span>
                  per month
                </div>
              </li> */}
              <li>
                <div className="inner-cell">
                50 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                60 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                60 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                100 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                40 €
                </div>
              </li>
            </ul>
          </div>


          <div className="plan-column ">
            <ul>
              <li className="plan-title">
                <div className="inner-cell">
                <small>Súrodenecká zľava <br />
                (resp. pre ďalšieho člena rodiny) je 20%</small>
                </div>
              </li>
              {/* <li className="subscription-price">
                <div className="inner-cell">
                  <span className="currency">$</span>
                  <span className="price">9.99</span>
                  per month
                </div>
              </li> */}
              <li>
                <div className="inner-cell">
                40 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                48 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                48 €
                </div>
              </li>
              <li>
                <div className="inner-cell">
                -
                </div>
              </li>
              <li>
                <div className="inner-cell">
                -
                </div>
              </li>
            </ul>
          </div>

          
        </div>
        <p>* študenti základnej, strednej, alebo vysokej školy maju cenu pre neobmedzený počet hodín mesačne za 50 €</p>
      </div>
      
    </div>
  </div>
</section>





        </div>
    )
}
export default AdultsComponent