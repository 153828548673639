import React from "react"
// import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'


const WelcomePage = () => {
  const {t} = useTranslation('translation')

    return (
      <div>

<section className="hg_section pt-40 pb-40">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="kl-title-block text-center">
              <h3 className="tbk__title fs-xxl fw-thin">
                <span className="fw-semibold fs-xxxl fs-xs-xl tcolor">
                { t('page.welcomeTo') } GRACIE JIU-JITSU<sup>®</sup> Levice</span>
              </h3>
              {/* <div style={{height: 10}} /> */}
            </div>
            <div className="screenshot-box">
              <div className="row">

                {/* 
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="left-side">
                    <h3 className="title">
                      <span className="fw-thin">
                        Gracie Jiu-Jitsu Levice is the only Gracie University Certified Training Center® in Slovakia. Our mission is to provide the highest level of self-defense instruction in a positive, healthy, and safe environment.
                        <br /><br />
                        Our instructors are dedicated to providing each of our students with the best self-defense instruction, support, and experience available in Gracie Jiu-Jitsu through group and private lessons.
                         <span className="fw-semibold">mature and premium work.</span>
                         </span>
                    </h3>
                  </div>
                </div> 
                */}

                <div className="col-sm-12 col-md-12 col-lg-12">
                  {/* <div className="screenshot-slider">
                    <div className="js-slick slick-initialized slick-slider slick-dotted slickSlider--activated" data-slick="{
                &quot;appendDots&quot;: &quot;.screenshot-slider .screenshotBox-slickNav&quot;,
                &quot;arrows&quot;: false,
                &quot;dots&quot;: true,
                &quot;dotsClass&quot;: &quot;slick-dots&quot;,
                &quot;infinite&quot;: true,
                &quot;autoplay&quot;: true,
                &quot;speed&quot;: 500,
                &quot;fade&quot;: true
              }">
                      <div className="slick-list draggable"><div className="slick-track" style={{opacity: 1, width: 1620}}><div className="slick-slide" data-slick-index={0} aria-hidden="true" style={{width: 540, position: 'relative', left: 0, top: 0, zIndex: 998, opacity: 0, transition: 'opacity 500ms linear 0s'}} tabIndex={-1} role="tabpanel" id="slick-slide00" aria-describedby="slick-slide-control00">
                            <img src="images/office-820390_640-580x380.jpg" width={580} height={380} alt title />
                          </div><div className="slick-slide slick-current slick-active slick-item--activated" data-slick-index={1} aria-hidden="false" style={{width: 540, position: 'relative', left: '-540px', top: 0, zIndex: 999, opacity: 1}} tabIndex={0} role="tabpanel" id="slick-slide01" aria-describedby="slick-slide-control01">
                            <img src="images/ipad-605439_640-580x380.jpg" width={580} height={380} alt title />
                          </div><div className="slick-slide" data-slick-index={2} aria-hidden="true" style={{width: 540, position: 'relative', left: '-1080px', top: 0, zIndex: 998, opacity: 0, transition: 'opacity 500ms linear 0s'}} tabIndex={-1} role="tabpanel" id="slick-slide02" aria-describedby="slick-slide-control02">
                            <img src="images/phone-690091_640-580x380.jpg" width={580} height={380} alt title />
                          </div></div></div>
                    </div>
                    <div className="screenshotBox-slickNav clearfix"><ul className="slick-dots" role="tablist" style={{}}><li className role="presentation"><button type="button" className="slickBtn" data-role="none" role="tab" tabIndex={-1} id="slick-slide-control00" aria-controls="slick-slide00" aria-label="1 of 3">1</button></li><li role="presentation" className="slick-active"><button type="button" className="slickBtn" data-role="none" role="tab" tabIndex={0} id="slick-slide-control01" aria-controls="slick-slide01" aria-label="2 of 3" aria-selected="true">2</button></li><li role="presentation" className><button type="button" className="slickBtn" data-role="none" role="tab" tabIndex={-1} id="slick-slide-control02" aria-controls="slick-slide02" aria-label="3 of 3">3</button></li></ul></div>
                  </div> */}

                  <div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}>
                  {/* https://www.youtube.com/watch?v=7bG2x5hzP4A */}
                    <iframe title="wp1" src="https://www.youtube.com/embed/7bG2x5hzP4A?rel=0&controls=0&showinfo=0" allowFullScreen />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
    )

}

export default WelcomePage
