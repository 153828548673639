import {
  GET_ESHOP_PRODUCTS,

  } from "./actionType";
  import HttpService from "../../services/HttpService"


  export const getEshopProducts = (filterValues) => ({
    type: GET_ESHOP_PRODUCTS,
    payload: {
      request: {
        method: HttpService.HttpMethods.POST,
        url: '/eshop/products/list',
        data: filterValues
      },
    }
  })
