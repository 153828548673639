import Keycloak from "keycloak-js";

const _kc = new Keycloak('/keycloak.json');

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc.init({
    onLoad: 'login-required',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    // redirectUri: "http://localhost:3333/eshop",
    // onLoad: 'check-sso',
    pkceMethod: 'S256',
  })
    .then(authenticated => {
        onAuthenticatedCallback();      
        console.log(authenticated)
    })

};


const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

// const getUsername = () => _kc.tokenParsed?.preferred_username
const getUsername = () => _kc.tokenParsed.preferred_username


const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const getRoles = () => _kc.tokenParsed.realm_access['roles']

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getRoles,
};

export default UserService;

