import React from "react"
import PageHeader from "../components/Header"
// import UserDetail from "../components/UserDetail"
// import PageSlider from "../components/Slider"
// import WelcomePage from "../components/WelcomePage"
// import Selector from "../components/Selector"
// import FreeTrial from "../components/FreeTrial"
import PageFooter from "../components/Footer"
import HarmonogramComponent from "../components/HarmonogramComponent"
import HeaderImage from "../components/HeaderImage"

const Harmonogram = () => {
    // const dispatch = useDispatch()
    // const { userProfile } = useSelector((state) => state)
    // const debug = JSON.stringify(userProfile, null, 2)

    // const [hasRoles, setHasRoles] = useState(false)
    // const [hasAwards, setHasAwards] = useState(false)
    // const [hasAdultRanks, setHasAdultRanks] = useState(false)
    


    return (
        <div>
            <PageHeader />
            <HeaderImage maintitle="Harmonogram" subtitle="tréningov" img="images/general-bg-5.jpg" />
            <HarmonogramComponent category={["adults-beginers", "adults-pro", "kids"]} />
            <PageFooter />
        </div>
    )
}

export default Harmonogram
