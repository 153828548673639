import React from "react"
import EshopCategories from "./EshopCategories"
import EshopProducts from "./EshopProducts"
import EshopProduct from "./EshopProduct"

// import { useTranslation } from 'react-i18next'

import { useSelector, useDispatch } from "react-redux"

import {
    getEshopCategories,
    getEshopProducts,
} from "../../modules/actions"


const EshopComponent = () => {

  // const {t} = useTranslation('translation')
  const dispatch = useDispatch()

  const { categories, products, error } = useSelector((state) => ({
      categories: state.EshopCategories.eshopCategories,
      products: state.EshopProductsReducer.eshopProducts,
      error: state.EshopCategories.error,
  }))
  
  
  React.useEffect(() => {
      dispatch(getEshopCategories({}))
      dispatch(getEshopProducts({}))
  }, [])
  


  return (
    <section className="hg_section">
      <div className="container">

        <div className="row">
          <div className="left_sidebar col-sm-12 col-md-12 col-lg-9 order-lg-1">
            {/* <EshopProduct /> */}

            <EshopProducts products={products} />
            
          </div>
          <div className="col-sm-12 col-md-12 col-lg-3">
            <div id="sidebar-widget" className="sidebar">
              <EshopCategories  categories={categories} />
            </div>
          </div>
        </div>
        
      </div>
    </section>
  )
}

export default EshopComponent
