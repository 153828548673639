// import {Link} from "react-router-dom";


function HeaderImage(props) {

    const image = "url("+ props.img +")"

    return (

        <div>

            <div id="page_header" className="page-subheader site-subheader-cst ">
                <div className="bgback" />
                <div className="th-sparkles" />
                <div className="kl-bg-source">
                    <div className="kl-bg-source__bgimage" style={{ backgroundImage: `${image}`, backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundPosition: '50% 50%', backgroundSize: 'cover' }} />
                    <div className="kl-bg-source__overlay" style={{ backgroundColor: 'rgba(53, 53, 53, 0.1)' }} />
                </div>
                <div className="ph-content-wrap d-flex"><div className="container align-self-center">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="clearfix" /></div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="subheader-titles" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: 15 }}>
                                    <h2 className="subheader-maintitle">{props.maintitle}</h2>
                                    <h4 className="subheader-subtitle">{props.subtitle}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default HeaderImage